/**
 * Visitor context specific to Edmunds for ad from google (gpt)
 *  zip=[user IP zipcode]
 *  uezip=[user entered zipcode]
 *
 *  userEnterZip - feature flag
 *
 * @param {Object} visitor Visitor specific params
 * @param {Object} region Visitor region location specific params
 *
 * @returns {Object}
 */
export function getVisitorContext(visitor, region, userEnterZip) {
  return {
    vid: visitor.id,
    edw: visitor.session,
    zip: userEnterZip ? region.ipZipCode : region.zipCode || region.ipZipCode,
    ...(userEnterZip && region.userSet ? { uezip: region.zipCode } : {}),
    dma: userEnterZip ? region.ipDma : region.dma,
    st: userEnterZip ? region.ipStateCode : region.stateCode,
    aseg: visitor.rttSegment,
  };
}
