// eslint-disable-next-line venom/no-restricted-classname
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { DFPManager } from '@edmunds/react-dfp';
import { connect } from 'react-redux';
import { isNode } from 'client/utils/environment';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { logger } from 'client/utils/isomorphic-logger';

import { NativeAdsCreativeConfigModel } from 'client/data/models/native-ads-creative-config';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';
import { getTotalAdUnitsToBeRendered } from 'client/site-modules/shared/components/ad-unit/ad-unit';
import { getPwtEnabled } from 'client/utils/pwt/get-pwt-enabled-page';
import { getBidEligibleAdUnits } from 'client/utils/pwt/get-bid-eligible-array';
import { isHeadlessBrowser } from 'client/utils/is-headless-browser';
import { getIasData } from 'client/utils/get-ias-data';
import { getPwtBids } from 'client/utils/pwt/get-pwt-bids';
import { ErrorBoundary } from 'site-modules/shared/components/error-boundary/error-boundary';

import { getVisitorContext } from './context/visitor-context';
import { getPageContext, getWtfContext } from './context/page-context';

const empty = {};

let loadAlreadyCalled = false;
let iasAlreadyCalled = false;
let pwtAlreadyCalled = false;
let frData = '';
let targeting = '';

export const setTestCalls = {
  loadCalled: bool => {
    loadAlreadyCalled = bool;
  },
  pwtCalled: bool => {
    pwtAlreadyCalled = bool;
  },
};

export const pwtHandler = {
  getBids: async () => {
    pwtAlreadyCalled = true;
    await getPwtBids(getBidEligibleAdUnits());
    DFPManager.load();
  },
  load: () => {
    if (window.PWT.isLoaded) {
      pwtHandler.getBids();
    } else {
      // eslint-disable-next-line no-console
      window.addEventListener('pwtLoaded', pwtHandler.getBids, false);
    }
  },
};

if (!isNode()) {
  DFPManager.configureSingleRequest(true);
  DFPManager.setCollapseEmptyDivs(true);
}

export class AdsContextUI extends Component {
  async componentDidMount() {
    // In the decorator the <AdsContext /> MUST come after the page content, so
    // by now the page has already mounted, so experiments from page are already known so all data for targetting is known
    // console.time('contextMountToDFPLoadStart');
    await this.setTargeting();
    // also, ideal if AdsContext comes after all AdUnits (sticky banner, pubpixel), that way if in future AdSlots are
    // rendered outside of async ClientSideRender, they would be synchronous, but all mounted and registered by the time
    // reaching this line of code
    DFPManager.on('slotRegistered', this.triggerLoadIfPossible);
    // if in future ads are rendered without CSR all ads might already be registered at this point, so callback above
    // won't fire, to account for that invoke triggerLoadIfPossible() below explicitly
    this.triggerLoadIfPossible();
  }

  async componentDidUpdate() {
    await this.setTargeting();
  }

  componentWillUnmount() {
    window.removeEventListener('pwtLoaded', pwtHandler.getBids, false);
  }
  async setTargeting() {
    const {
      page,
      visitor,
      vehicle,
      region,
      legacy,
      dealer,
      userEnterZip,
      pageLocation,
      isCheckHeadlessBrowser,
      isEnableIAS,
      ads,
    } = this.props;

    const activeCampaigns = ExperimentUtil.debug.getVenomAssignmentsOnPage();
    const headlessBrowser = isCheckHeadlessBrowser && (await isHeadlessBrowser());

    if (iasAlreadyCalled === false) {
      iasAlreadyCalled = true;
      const iasData =
        isEnableIAS && (await getIasData(visitor).catch(error => logger('error', error, `Error in getIasData`)));
      frData = get(iasData, 'fr') || null;
    }

    targeting = {
      ...getWtfContext(activeCampaigns),
      ...getVisitorContext(visitor, region, userEnterZip),
      ...getPageContext(page, pageLocation, vehicle, legacy, dealer, headlessBrowser, frData, ads.utmAds),
    };

    DFPManager.setTargetingArguments(targeting);
  }

  /**
   * triggers react-dfp/DFPManager.load() if react-dfp/AdSlots from our AdUnits have all been registered with DFPManager
   * note that AdUnits are mounted synchronously but AdSlots are registered later asynchronously because they are inside of
   * ClientSideRender
   *
   * DFPManager.load() only needs to be called once during web app load. On the first call to DFPManager.load() the first
   * SRA network call will trigger.Any subsequent AdSlot coming into page will automatically be handled by DFPManager,
   * generating it's own network request.
   *
   * purpose of this function is to ensure that DFPManager.load() is called at the right time during page load - after all the
   * AdUnits on page are mounted and their corresponding AdsSlot registers() are complete, not before that - this way there
   * is only a single network call to securepubads.g.doubleclick.net/gampad/ads?... which means page is wired correctly for
   * SRA.
   *
   */
  triggerLoadIfPossible = () => {
    if (loadAlreadyCalled) {
      return;
    }
    const totalAdUnitsToBeRendered = getTotalAdUnitsToBeRendered();
    const registeredSlots = DFPManager.getRegisteredSlots();
    const numRegisteredSlots = Object.keys(registeredSlots).length;

    if (numRegisteredSlots && numRegisteredSlots >= totalAdUnitsToBeRendered) {
      const { pwtEnabled } = this.props;
      loadAlreadyCalled = true;
      // console.timeEnd('contextMountToDFPLoadStart');
      DFPManager.removeListener('slotRegistered', this.triggerLoadIfPossible);
      /*
       * Reference client/utils/get-pwt-bids.js
       * `pwtHandlers.refresh();`
       * For refreshing of the googletag pubads
       */
      if (pwtEnabled && pwtAlreadyCalled === false && window.PWT) {
        pwtHandler.load();
      } else if (!pwtEnabled) {
        DFPManager.load();
      }
      window.EDM.Venom.adsLoadTriggered = true;
    }
  };

  render() {
    const { pageAdsList, pageAdsListVerified, pageAdsListSeparatedCalls } = this.props;

    return (
      <ErrorBoundary>
        <div
          data-ad-names={Array.from(pageAdsListSeparatedCalls || pageAdsList || []).join()}
          data-ad-model-verified={pageAdsListVerified ? Array.from(pageAdsListVerified).join() : 'verifying'}
          hidden
        />
      </ErrorBoundary>
    );
  }
}
// there is a bug in eslint - it thinks these props are not used
AdsContextUI.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  ads: PropTypes.shape({
    adsts: PropTypes.shape({
      value: PropTypes.number,
      trigger: PropTypes.string,
    }),
    utmAds: PropTypes.shape({}),
  }).isRequired,
  page: PropTypes.shape({
    // eslint-disable-line react/no-unused-prop-types
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  }).isRequired,
  pageLocation: PropTypes.shape({
    // eslint-disable-line react/no-unused-prop-types
    search: PropTypes.string.isRequired,
  }).isRequired,
  legacy: PropTypes.shape({
    pageName: PropTypes.string,
    categoryName: PropTypes.string,
  }).isRequired,
  vehicle: PropTypes.shape({
    // eslint-disable-line react/no-unused-prop-types
    type: PropTypes.object,
    make: PropTypes.object,
    model: PropTypes.object,
    modelYear: PropTypes.object,
    subModel: PropTypes.object,
    publicationState: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  dealer: PropTypes.shape({
    rooftopId: PropTypes.string,
  }).isRequired,
  region: PropTypes.shape({
    // eslint-disable-line react/no-unused-prop-types
    zipCode: PropTypes.string.isRequired,
    ipZipCode: PropTypes.string,
    ipStateCode: PropTypes.string,
    dma: PropTypes.string.isRequired,
    stateCode: PropTypes.string.isRequired,
  }).isRequired,
  visitor: PropTypes.shape({
    // eslint-disable-line react/no-unused-prop-types
    id: PropTypes.string.isRequired,
    session: PropTypes.string.isRequired,
    isIVT: PropTypes.string,
    rttSegment: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  userEnterZip: PropTypes.bool,
  isCheckHeadlessBrowser: PropTypes.bool,
  isEnableIAS: PropTypes.bool,
  pwtEnabled: PropTypes.bool,
  pageAdsList: PropTypes.arrayOf(PropTypes.string),
  pageAdsListVerified: PropTypes.arrayOf(PropTypes.string),
  pageAdsListSeparatedCalls: PropTypes.arrayOf(PropTypes.string),
};

AdsContextUI.defaultProps = {
  userEnterZip: false,
  isCheckHeadlessBrowser: false,
  isEnableIAS: false,
  pwtEnabled: false,
  visitor: {
    ivtType: null,
    rttSegment: null,
  },
  pageAdsList: null,
  pageAdsListVerified: null,
  pageAdsListSeparatedCalls: null,
};

/**
 * Maps store state pageContext to component properties
 *
 * @param  {Object} state Store state
 * @return {Object}       State to props mapping
 */
export const mapStateToProps = state => ({
  ads: state.pageContext.ads || empty,
  page: state.pageContext.page || empty,
  pageLocation: state.pageContext.location || empty,
  legacy: state.pageContext.legacy || empty,
  region: (state.visitor && state.visitor.location) || empty,
  visitor: state.visitor || empty,
  vehicle: state.pageContext.vehicle || empty,
  dealer: state.pageContext.dealer || empty,
  userEnterZip: state.featureFlags.userEnterZip,
  isEnableIAS: state.featureFlags.enableIAS,
  isCheckHeadlessBrowser: state.featureFlags.headlessBrowser,
  pwtEnabled: getPwtEnabled(state),
});

export const stateToPropsConfig = {
  pageAdsList: bindToPath('pageAdsList', NativeAdsCreativeConfigModel),
  pageAdsListVerified: bindToPath('pageAdsListVerified', NativeAdsCreativeConfigModel),
  pageAdsListSeparatedCalls: bindToPath('pageAdsListSeparatedCalls', NativeAdsCreativeConfigModel),
};

export const AdsContext = connect(mapStateToProps)(connectToModel(AdsContextUI, stateToPropsConfig));
